<template>
  <b-card>
    <b-row class="d-flex justify-content-between">
      <b-col class="d-flex flex-column justify-content-center">
        <h3
          class="font-weight-bolder"
        >
          {{ raffle.name }}
        </h3>
        <div class="mt-1">
          <b-badge
            v-if="raffle.categories[0]"
            variant="warning"
          >
            {{ raffle.categories[0].name }}
          </b-badge>
          <p class="item-date mt-1 font-weight-bolder">
            Fecha del sorteo: {{ raffle.raffle_date|dateNtime }}
          </p>
        </div>
      </b-col>
      <b-col sm="auto">
        <b-img
          v-if="raffle.thumbnails[0]"
          class="img-sizing"
          :src="raffle.thumbnails[0]"
          fluid
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BBadge, BRow, BCol, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BImg,
  },
  props: {
    raffle: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.img-sizing{
  height: 125px;
  width: 225px;
}

</style>
