<template>
  <div>
    <raffle-summary-card :raffle="currentRaffle" />
    <b-card>
      <b-row>
        <b-col class="d-flex justify-content-center">
          <b-form-group>
            <v-select
              v-model="searchQuery"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="folio"
              :options="tickets"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-2 text-center"
        >
          <h5 v-if="winnerName != null">
            Nombre: {{ winnerName }}
          </h5>
          <h5 v-if="winnerEmail != null">
            Email: {{ winnerEmail }}
          </h5>
          <h5 v-else>
            Este boleto no ha sido comprado
          </h5>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col class="d-flex justify-content-end my-1">
        <b-button
          variant="primary"
          @click="confirmationModal"
        >
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BButton,
  BCol,
  BRow,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import RaffleSummaryCard from '@/@core/components/raffles/RaffleSummaryCard.vue'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BFormGroup,
    RaffleSummaryCard,
    vSelect,
  },
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      searchQuery: null,
      winnerName: null,
      winnerEmail: null,
    }
  },
  computed: {
    ...mapGetters('raffles', ['currentRaffle', 'tickets']),
  },
  watch: {
    searchQuery(val) {
      if (val === null) {
        this.getTickets({ raffle_id: this.$route.params.raffle_id, meta: { pagination: { all: true } } })
      } else {
        this.getTickets({
          raffle_id: this.$route.params.raffle_id,
          by_folio: val.folio || null,
        })
          .then(response => {
            if (response.data[0].user) {
              this.winnerName = response.data[0].user.name
              this.winnerEmail = response.data[0].user.email
            } else {
              this.winnerName = null
              this.winnerEmail = null
            }
          })
      }
    },
  },
  mounted() {
    this.getRaffle(this.$route.params.raffle_id)
    this.getTickets({ raffle_id: this.$route.params.raffle_id, meta: { pagination: { all: true } } })
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  methods: {
    ...mapActions('raffles', ['getRaffle', 'getTickets', 'setRaffleWinner']),
    confirmationModal() {
      this.$bvModal.msgBoxOk('¿Es correcta la información que deseas enviar?', {
        title: 'Confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      })
        .then(value => {
          if (value === true) {
            this.setRaffleWinner({ ticket_id: this.searchQuery.id, raffle_id: this.$route.params.raffle_id })
              .then(() => {
                this.$swal({
                  title: 'Ganador registrado!',
                  text: 'Gracias',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.$router.push({ name: 'RafflesList' })
              })
          }
        })
        .catch(() => {
          // An error occurred
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
